import { store } from './../store/store';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/HomePage.vue'
import LoginPage from '@/views/LoginPage.vue';
import CallbackPage from '@/views/CallbackPage.vue';
import AdminPage from '@/views/AdminPage.vue';
import UiPage from '@/views/UiPage.vue'
import ReservationPage from '@/views/ReservationPage.vue';
import actions from '@/store/actions';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/callback',
    name: 'Callback',
    component: CallbackPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    meta: {
      requiresAuth: true,
      beforeEnter: function () {
        actions.loadWeekReservations();
        this.loadReservationsInterval = setInterval(function () {
          actions.loadWeekReservations();
        }, 60 * 1000);
      },
      beforeLeave: function () {
        clearInterval((this as any).loadReservationsInterval);
      },
    },
  },
  {
    path: '/reservation',
    name: 'Reservation',
    component: ReservationPage,
    meta: {
      requiresAuth: true,
      beforeEnter: function () {
        if (!store.state.currentDay) router.push('/');
      },
      beforeLeave: function () {
        setTimeout(() => {
          store.commit('SET_CURRENT_RESERVATION', null);
        }, 500);
      },
    },
  },
  {
    path: '/ui',
    name: 'Ui',
    component: UiPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPage,
    beforeEnter: function (to, from, next) {
      const isAdmin = store.getters.isAdmin
      isAdmin ? next() : next('/')
    },
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/noie',
  //   name: 'Noie',
  //   component: NoIE,
  //   meta: { requiresAuth: false },
  // },
  // {
  //   path: '*',
  //   name: 'Page404',
  //   component: Page404,
  //   meta: { requiresAuth: false },
  // },
]

const router = createRouter({
  // temporarily hardcoded config for test deployment
  // history: createWebHistory(''),
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const loggedIn = store.getters.isLoggedIn;

  // handle protected routes
  const isAuthRequired = to.matched.some((record) => record.meta.requiresAuth);
  if (isAuthRequired) {
    if (!loggedIn) {
      // redirect to /login from protected route
      next('/login');
      return;
    }
  } else {
    if (to.name === 'Login' && loggedIn) {
      // redirect to home if already logged in
      next('/home');
      return;
    }
  }

  if (to.meta.beforeEnter) {
    (to.meta as any).beforeEnter();
  }

  next();
});

// handle callbacks after route can be rendered, like load view data
router.afterEach((to, from) => {
  if (from.meta.beforeLeave) {
    (from.meta as any).beforeLeave();
  }
})

export default router


