import EnvService from '@/services/env.service';
import LocalStorageService from '@/services/localStorage.service';
import actions from '@/store/actions';

function showWelcome() {
  console.clear();
  console.info('Welcome to Parking Reservation App! 🚗');
}

function setInitialLanguage() {
  const lastLanguage = LocalStorageService.getLanguage();
  if (lastLanguage) actions.setLanguage(lastLanguage);
}

function setWindowTitle() {
  const envService = new EnvService();
  const currentBuilding = envService.getCurrentBuilding();
  if (currentBuilding !== 'unknown') {
    document.title = `Park.it ${currentBuilding.charAt(0).toUpperCase() + currentBuilding.slice(1)}`;
  }
}

// initial actions Before application is rendered
export async function init(callback: {(): void;}): Promise<void> {
  showWelcome();

  // set initial language if exist
  setInitialLanguage()

  // load user session if exist
  await actions.initSession();

  // Set dynamic title based on current building
  setWindowTitle();

  // populate currentWeek in vuex
  await actions.populateCurrentWeek();

  //populate bookings
  await actions.populateBookings();

  //populate logs
  await actions.populateLogs();

  if (callback) callback();
}

// initial actions After application is rendered
export async function mountedInit(): Promise<void> {
  // example: restore user language / user data
}

export default init;
