// ADFS api information:
// https://fs.ista.com/adfs/.well-known/openid-configuration

import { UserManager, User, UserManagerSettings } from 'oidc-client-ts';
export default class AuthService {
    public userManager: UserManager;

    constructor() {
        // universal config
        const ADFS_URL = process.env.VUE_APP_ADFS_URL;
        const ADFS_REDIRECT_URL = location.protocol + '//' + location.host;
        const ADFS_CLIENT_ID = process.env.VUE_APP_ADFS_CLIENT_ID;

        // temporarily hardcoded config for test deployment
        // const ADFS_URL = 'https://fs.ista.com';
        // const ADFS_REDIRECT_URL = 'https://parkit.int.ista.net';
        // const ADFS_CLIENT_ID = 'd5f3d010-2b10-4193-b0ec-4b486891dfb7';
        // const ADFS_PROXY_DOMAIN = 'http://localhost:1338';
        const TOKEN_ENDPOINT = process.env.NODE_ENV === 'production'
            ? ADFS_URL
            : process.env.VUE_APP_ADFS_PROXY_URL + ':' + process.env.VUE_APP_ADFS_PROXY_PORT;

        const userManagerSettings: UserManagerSettings = {
            client_id: ADFS_CLIENT_ID,
            redirect_uri: ADFS_REDIRECT_URL + '/callback/',
            response_type: 'code',
            scope: 'openid profile',
            authority: ADFS_URL,
            post_logout_redirect_uri: ADFS_REDIRECT_URL + '/',
            filterProtocolClaims: true,
            automaticSilentRenew: true,
            metadata: {
                issuer: ADFS_URL + '/adfs',
                authorization_endpoint: ADFS_URL + '/adfs/oauth2/authorize',
                token_endpoint: TOKEN_ENDPOINT + '/adfs/oauth2/token/',
                jwks_uri: ADFS_URL + '/adfs/discovery/keys',
                userinfo_endpoint: ADFS_URL + '/adfs/userinfo',
                end_session_endpoint: ADFS_URL + '/adfs/oauth2/logout',
            }
        };

        this.userManager = new UserManager(userManagerSettings);
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }
}