import { createStore } from "vuex";
import { IDay } from '@/store/interfaces';
import moment, { Moment } from 'moment';
import {IUser, ITeam, IRole, IBooking, ILog} from '@/services/authorization.mock';
import { User } from "oidc-client-ts";

export interface IState {
  bookings: IBooking[] | null,
  logs: ILog[] | null,
  user: {
    language: string,
    session: User | null, // @TODO rename to sessionAdfs
    sessionParkit: any, // @TODO typing
    isRememberSelected: boolean,
  };
  browsedWeekStart: Moment;
  browsedWeekEnd: Moment;
  bookingDate: Moment;
  logsDate: Moment;
  currentWeek: IDay[];
  currentDay: IDay | null;
  users: IUser[] | null,
  teams: ITeam[] | null,
  roles: IRole[] | null,
  parkingSpaces: any[] | null,
  isPostingReservation: boolean,
  isDeletingReservation: boolean,
  isGettingReservations: boolean,
  isGettingBookings: boolean,
  isGettingLogs: boolean,
  isLoggingIn: boolean,
  isLoadingUsers: boolean,
  isLoadingTeams: boolean,
  isLoadingBookings: boolean,
  isLoadingLogs: boolean,
  currentReservation: any, // @TODO typing
  isLoadingSpacesSummary: boolean,
  spacesSummary: Record<string, any>,
  isOfflineMode: boolean,
  isAccountAwaitingActivation: boolean,
}

// @see https://github.com/vuejs/vuex/tree/v4.0.0-rc.1

const defaultUserState = {
  language: 'en',
  session: null,
  sessionParkit: null,
  isRememberSelected: false,
}

export const store = createStore({
  state(): IState {
    return {
      user: defaultUserState,
      bookingDate: moment(),
      logsDate: moment(),
      browsedWeekStart: moment().startOf('isoWeek'),
      browsedWeekEnd: moment().endOf('isoWeek'),
      currentWeek: new Array<IDay>(),
      currentDay: null,
      currentReservation: null,
      users: null,
      teams: null,
      bookings: null,
      logs: null,
      roles: null,
      parkingSpaces: null,
      isPostingReservation: false,
      isDeletingReservation: false,
      isGettingReservations: false,
      isGettingBookings: false,
      isGettingLogs: false,
      isLoggingIn: false,
      isLoadingSpacesSummary: false,
      spacesSummary: {},
      isLoadingUsers: false,
      isLoadingTeams: false,
      isLoadingBookings: false,
      isLoadingLogs: false,
      isOfflineMode: false,
      isAccountAwaitingActivation: false,
    };
  },

  // MUTATIONS ( set the state )
  mutations: {
    SET_IS_LOGGING_IN(state: IState, isLoggingIn: boolean) {
      state.isLoggingIn = isLoggingIn;
    },
    SET_USER_IS_REMEMBER_SELECTED(state: IState, isRememberSelected: boolean) {
      state.user.isRememberSelected = isRememberSelected;
    },
    SET_CURRENT_RESERVATION(state: IState, currentReservation: any) {
      state.currentReservation = currentReservation;
    },
    SET_IS_POSTING_RESERVATION(state: IState, newValue: boolean) {
      state.isPostingReservation = newValue;
    },
    SET_IS_DELETING_RESERVATION(state: IState, newValue: boolean) {
      state.isDeletingReservation = newValue;
    },
    SET_IS_GETTING_RESERVATIONS(state: IState, newValue: boolean) {
      state.isGettingReservations = newValue;
    },
    SET_IS_GETTING_BOOKINGS(state: IState, newValue: boolean) {
      state.isGettingBookings = newValue;
    },
    SET_IS_GETTING_LOGS(state: IState, newValue: boolean) {
      state.isGettingLogs = newValue;
    },
    SET_BOOKING_DATE(state: IState, newValue: Moment) {
      state.bookingDate = newValue;
    },
    SET_LOGS_DATE(state: IState, newValue: Moment) {
      state.logsDate = newValue;
    },
    SET_BROWSED_WEEK_START(state: IState, newStartMoment: Moment) {
      state.browsedWeekStart = newStartMoment;
    },
    SET_BROWSED_WEEK_END(state: IState, newEndMoment: Moment) {
      state.browsedWeekEnd = newEndMoment;
    },
    SET_USER(state: IState, user) {
      state.user = user;
    },
    SET_USER_SESSION(state: IState, sessionData) {
      state.user.session = sessionData;
    },
    SET_USER_SESSION_PARKIT(state: IState, session: any) {
      state.user.sessionParkit = session;
    },
    SET_USER_LANGUAGE(state: IState, language: string) {
      state.user.language = language;
    },
    SET_CURRENT_WEEK(state: IState, newWeek) {
      state.currentWeek = newWeek;
    },
    SET_CURRENT_DAY(state: IState, newDay) {
      state.currentDay = newDay;
    },
    SET_USERS(state: IState, users: IUser[]) {
      state.users = users;
    },
    CLEAR_USERS(state: IState) {
      state.users = null;
    },
    SET_TEAMS(state: IState, teams: ITeam[]) {
      state.teams = teams;
    },
    CLEAR_TEAMS(state: IState) {
      state.teams = null;
    },
    SET_ROLES(state: IState, roles: IRole[]) {
      state.roles = roles;
    },
    CLEAR_BOOKINGS(state: IState) {
      state.bookings = null
    },
    SET_BOOKINGS(state: IState, bookings: IBooking[]) {
      state.bookings = bookings
    },
    CLEAR_LOGS(state: IState) {
      state.logs = null
    },
    SET_LOGS(state: IState, logs: ILog[]) {
      state.logs = logs
    },
    SET_PARKING_SPACES(state: IState, parkingSpaces: any[]) {
      state.parkingSpaces = parkingSpaces;
    },
    SET_IS_LOADING_SPACES_SUMMARY(state: IState, isLoadingSpacesSummary: boolean) {
      state.isLoadingSpacesSummary = isLoadingSpacesSummary;
    },
    SET_SPACES_SUMMARY(state: IState, spacesSummary: Record<string, any>) {
      state.spacesSummary = spacesSummary;
    },
    SET_IS_LOADING_USERS(state: IState, isLoadingUsers: boolean) {
      state.isLoadingUsers = isLoadingUsers;
    },
    SET_IS_LOADING_TEAMS(state: IState, isLoadingTeams: boolean) {
      state.isLoadingTeams = isLoadingTeams;
    },
    SET_IS_LOADING_BOOKINGS(state: IState, isLoadingBookings: boolean) {
      state.isLoadingBookings = isLoadingBookings;
    },
    SET_IS_LOADING_LOGS(state: IState, isLoadingLogs: boolean) {
      state.isLoadingLogs = isLoadingLogs;
    },
    SET_IS_OFFLINE_MODE(state: IState, isOfflineMode: boolean) {
      state.isOfflineMode = isOfflineMode;
    },
    SET_IS_ACCOUNT_AWAITING_ACTIVATION(state: IState, isAccountAwaitingActivation: boolean) {
      state.isAccountAwaitingActivation = isAccountAwaitingActivation;
    },
  },

  // ACTIONS (asynchronous)
  actions: {
    // we use actions.ts for better typing
  },

  getters: {
    isLoggedIn(state) {
      return !!(state.user?.session?.access_token);
    },
    isGuest(state) {
      const isAdmin = !!state.user.session?.profile.is_admin;
      const isSuperAdmin = !!state.user.session?.profile.is_super_admin;
      const hasEmptyRoles = !state.user.sessionParkit?.roles.length;
      const hasEmptyTeams = !state.user.sessionParkit?.teams.length;
      const isGuest = (hasEmptyRoles && hasEmptyTeams) && !(isAdmin || isSuperAdmin)
      return isGuest;
    },
    isAdmin(state) {
      const isAdmin = state.user.session?.profile.is_admin === "true";
      const isSuperAdmin = state.user.session?.profile.is_super_admin === "true";
      return isAdmin || isSuperAdmin;
    }
  },
});

export default store;